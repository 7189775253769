@use '../../_shared.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  border-bottom: solid 1px shared.$third-color;
  @media all and (max-width: 678px) {
    flex-direction: column;
  }
  @media all and (max-width: 430px) {
    padding: 0;
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media all and (max-width: 430px) {
      flex-direction: column;
      margin-top: 18px;
    }
  }
  & ul {
    padding: 0;
    margin: 0;
    @media all and (max-width: 430px) {
      margin-top: 0;
      margin-bottom: 18px;
    }
  }
  &__nav {
    &__link {
      list-style-type: none;
    }
  }
}
