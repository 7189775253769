@use '../../_shared.scss';

#map {
  width: 60%;
  margin: 36px auto;
  padding: 10px;
  background: shared.$third-color;
  @media all and (max-width: 1100px) {
    width: 80%;
  }
  @media all and (max-width: 1000px) {
    width: 90%;
  }
}
.leaflet-container {
  width: 100%;
  margin: auto;
  height: 50vh;
}
